<template>
    <b-card>
        <validation-observer ref="simpleRules">
            <b-form>
                <!-- Section Informations -->
                <h4> {{$t('Device Information')}} </h4>
                <hr style="margin-bottom: 16px;">
                <b-row>
    
                    <!-- Field: Device Model-->
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('Device Model')"  label-for="device-model">
                            <validation-provider #default="{ errors }" name="device-model" rules="required">
                                <v-select :state="errors.length > 0 ? false:null" v-model="deviceData.deviceModelId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="deviceOptions" :reduce="val => val.value" :clearable="false" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
    
                    <!-- Field: Modem Identifier -->
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('Modem Identifiers')"  label-for="modem-identifiers">
                            <validation-provider #default="{ errors }" name="modem-identifiers" rules="required">
                                <b-form-file v-model="file1" accept=".txt" :state="Boolean(file1)" placeholder="Choose a file or drop it here" drop-placeholder="Drop a file here"></b-form-file>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                     <!-- Btn: Save Submit -->
                     <b-col v-if="!user.role.endsWith('VI')" cols="12">
                        <b-button variant="primary" type="button" @click.prevent="validationForm">
                            {{$t('Submit')}}
                        </b-button>
                        
                    </b-col>

                                  
                </b-row>
               
            </b-form>
        </validation-observer>
    </b-card>
    </template>
    
    <script>
    import {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
    } from 'bootstrap-vue'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BFormFile
    } from 'bootstrap-vue'
    import {
        ref,
        onUnmounted
    } from '@vue/composition-api'
    import router from '@/router'
    import store from '@/store'
    import deviceStoreModule from '../deviceStoreModule'
    
    import {
        VueAutosuggest
    } from 'vue-autosuggest'
    
    //import Cleave from 'vue-cleave-component'
    import Cleave from 'cleave.js';
    //import 'cleave.js/dist/addons/cleave-phone.br'
    
    import vSelect from 'vue-select'
    
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from '@validations'
    
    export default {
    
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
    
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BCardText,
            BFormTextarea,
            BFormFile,
    
            VueAutosuggest,
            Cleave,
            vSelect
    
        },
        data() {
            return {
                myId: router.currentRoute.params.id,
                required,
                email,
                confirmed,
                url,
                between,
                alpha,
                integer,
                password,
                min,
                digits,
                alphaDash,
                length,
    
                datasuggest: [],
                filteredOptions: [],
                inputProps: {
                    id: 'autosuggest__input',
                    class: 'form-control',
                    placeholder: "Search for Sim Cards ...",
                },
                limit: 3,
                selected: null,
    
                myMask: {
                    //prefix: '55',
                    delimiters: ['-'],
                    blocks: [1, 7],
                    numericOnly: true
                    //uppercase: true,
                    //phone: true,
                    //phoneRegionCode: 'BR',
                },
                
                toInsert: []
            }
        },
    
        methods: {
    
            
    
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.importFile()
                    }
                })
            },
    
            clearForm() {
                this.deviceData = {
                    id: '',
                    modemIdentifier: "",
                    deviceModelId: "",
                    mobileOperatorId: null,
                    mobileOperatorProviderId: null,
                    simCardNumber: "",
                    customerId: "",
                    note: "",
    
                }
            },
    
            importFile() {
                var self = this;
                if (this.file1) {
                    this.showImportFile = false;
                    var fr = new FileReader();
                    fr.onload = function(e) {
                        var content = e.target.result;
                        var lines = content.split('\n');
                        self.processFile(lines);
                    }
                    fr.readAsText(this.file1, "UTF-8");
                }
            },
            
            processFile(lines) {
                this.isBusy = true;
                var self = this;
                self.toInsert = [];
                lines.forEach((line, index) => {
                    if (line.length <= 17 && line.length > 5) {
                        var id = line.replace("\r", "").replace("\n", "");
                        self.toInsert.push(id);
                    }
                });
                this.isBusy = false;
                this.confirmImport();
            },
            
            confirmImport() {
                var self = this;
                if (self.toInsert.length > 0) {
                    
                    store.dispatch('app-device/addBulk', {
                        deviceData: { ids: self.toInsert, modelId: this.deviceData.deviceModelId }
                    })
                    .then(response => {
                        this.msgDialog('Successful device import', 'success')
                        
                        router.push({
                            name: 'list-device'
                        })
                    })
                    .catch(error => {
                        this.msgDialog('FAILURE to import devices', 'error')
                        console.log(error)
                    })
                    
                    //deviceApi.createDeviceBulk({ ids: self.toInsert, modelId: self.deviceModelId })
                    //	.then(function (data) {
                    //		self.showInformation = true;
                    //	});	
                
                }
            },		
            
            msgDialog(msg, icon) {
                this.$swal({
                    position: 'top-end',
                    icon: icon,
                    title: msg,
                    showConfirmButton: false,
                    timer: 1500 * 2,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
    
                    buttonsStyling: false,
                })
            },
    
            selectHandler(option) {
                this.selected = option.item
                this.filteredOptions = []
                this.searchQuery = option.item.number
                this.deviceData.simCardNumber = option.item.number
    
                console.log(`option selecionado `, this.deviceData.customerId)
            },
    
            onInputChange(text) {
                if (text === '' || text === undefined || text.length < 3) {
    
                    return
                }
    
                store.dispatch('app-device/fetchSimCard', {
                        q: this.searchQuery,
    
                    })
                    .then(response => {
                        console.log(`resposta auto `, response.data)
    
                        this.filteredOptions = [{
                            data: response.data.data,
                        }]
    
                    })
                    .catch(error => {
    
                        console.log(error)
    
                    })
            },
            suggestionSelected() {
                console.log('Selecionado', this.selected)
            }
        },
        setup() {
            const deviceData = ref({
                id: '',
                modemIdentifier: "",
                deviceModelId: "",
                mobileOperatorId: null,
                mobileOperatorProviderId: null,
                simCardNumber: "",
                customerId: "",
                note: "",
            })
            const searchQuery = ref(``)
    
            const providerOptions = ref([])
            const operatorOptions = ref([])
            const deviceOptions = ref([])
    
            const DEVICE_APP_STORE_MODULE_NAME = 'app-device'

          const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}
    
            // Register module
            if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICE_APP_STORE_MODULE_NAME, deviceStoreModule)
    
            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME)
            })
    
            if (router.currentRoute.params.id != undefined) {
    
                store.dispatch('app-device/fetchDevice', {
                        id: router.currentRoute.params.id
                        //id: '099122db-1dad-4c62-819d-071e49f3298e'
                    })
                    .then(response => {
                        deviceData.value = response.data
                        console.log(`deviceData --`, response.data)
    
                        deviceData.value.id = response.data.id
                        deviceData.value.modemIdentifier = response.data.modemIdentifier
                        deviceData.value.simCardNumber = response.data.simCardNumber
                        deviceData.value.deviceModelId = response.data.deviceModelId
    
                        deviceData.value.note = response.data.note
    
                        if (response.data.simCardNumber) {
                            searchQuery.value = response.data.simCardNumber
    
                            store
                                .dispatch('app-device/fetchProvider', {
                                    number: response.data.simCardNumber,
    
                                })
                                .then(res => {
                                    deviceData.value.mobileOperatorId = res.data.mobileOperatorId;
                                    deviceData.value.mobileOperatorProviderId = res.data.mobileOperatorProviderId;
                                    console.log(`deviceData --`, deviceData.value)
                                    
                                })
                                .catch((e) => {
                                    console.log(e);
    
                                })
    
                        }
    
                    })
                    .catch(error => {
                        if (error.response.status === 404) {
                            deviceData.value = undefined
                        }
                    })
            } else {
                deviceData.value = {
    
                    id: '',
                    modemIdentifier: "",
                    deviceModelId: "",
                    mobileOperatorId: null,
                    mobileOperatorProviderId: null,
                    simCardNumber: "",
                    customerId: "",
                    note: "",
    
                }
            }
    
            //Fetch Device Mobile Operators
            store.dispatch('app-device/fetchMobileOperators')
                .then(response => {
                    response.data.data.map(function (v, key) {
                        operatorOptions.value.push({
                            label: v.name,
                            value: v.id
                        }, );
                    });
    
                })
                .catch(error => {
    
                })
    
            //Fetch Device Mobile Providers
            store.dispatch('app-device/fetchMobileProviders')
                .then(response => {
                    response.data.data.map(function (v, key) {
                        providerOptions.value.push({
                            label: v.name,
                            value: v.id
                        });
                    });
    
                })
                .catch(error => {
    
                })
    
            //Fetch Device Models
            store.dispatch('app-device/fetchDeviceModel')
                .then(response => {
                    response.data.data.map(function (v, key) {
                        deviceOptions.value.push({
                            label: v.name,
                            value: v.id
                        });
                    });
    
                })
                .catch(error => {
    
                })
    
            return {
                deviceData,
                searchQuery,
                providerOptions,
                operatorOptions,
                deviceOptions,
              user
            }
        },
    }
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-autosuggest.scss';
    </style>
    
    <style>
    
    </style>
    